.contact-form {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  margin-top: 25px;
}

#user-name,
#user-email,
#user-text {
  align-self: center;
  min-width: 50%;
  margin: 10px 0px;
}

.contact-btn {
  align-self: center;
  text-decoration: none;
  color: white;
  background-color: #70a288;
  padding: 15px 25px;
  margin-bottom: 20px;
  border: none;
}

.contact-btn:hover {
  background-color: black;
}
