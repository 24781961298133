@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Montserrat&display=swap');

html {
  font-family: 'Montserrat', sans-serif;
  min-width: 100%;
  text-align: center;
  background-color: #292929;
  color: white;
}

h1,
h2,
h3,
h4 {
  font-family: 'Caveat', cursive;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 20px;
}

.main-box {
  min-width: 100%;
  padding-bottom: 2.5rem;
}

.content-wrap {
  position: relative;
  min-height: 100vh;
}
