@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Montserrat&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: 'Montserrat', sans-serif;
  min-width: 100%;
  text-align: center;
  background-color: #292929;
  color: white;
}

h1,
h2,
h3,
h4 {
  font-family: 'Caveat', cursive;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 20px;
}

.main-box {
  min-width: 100%;
  padding-bottom: 2.5rem;
}

.content-wrap {
  position: relative;
  min-height: 100vh;
}

.navbar-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.name-title {
  font-size: 80px;
  color: #70a288;
  margin-top: 25px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 380px) {
  .name-title {
    font-size: 40px;
  }
}

.navbar {
  background-color: #292929;
  padding: 20px 0px;
}

.navbar ul {
  display: -webkit-flex;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
}

.navbar li a {
  color: white;
  display: block;
  font-size: 1.2em;
  height: 20px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px 4px 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

@media only screen and (max-width: 380px) {
  .navbar li a {
    font-size: 11px;
    padding: 0;
  }
}

@media only screen and (min-width: 550px) {
  .navbar li a {
    font-size: 20px;
  }
}

.navbar li a:hover {
  color: #70a288;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

/* Animated Bottom Line */
.navbar li a:before,
.navbar li a:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: -1px;
  background: black;
}

.navbar li a:before {
  left: 0;
  transition: 0.5s;
}

.navbar li a:after {
  background: black;
  right: 0;
}

.navbar li a:hover:before {
  background: black;
  width: 100%;
  transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

.navbar li a:hover:after {
  background: transparent;
  width: 100%;
}

.about-me-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
}

.about-title {
  margin-bottom: 0;
  color: black;
}

.about-me-text {
  padding: 0px 15px 0 15px;
  color: black;
  max-width: 700px;
}

.my-photo {
  margin-top: 20px;
  border: solid 2px #70a288;
  border-radius: 400px;
  max-width: 75%;
}

@media only screen and (min-width: 600px) {
  .my-photo {
    max-width: 25%;
  }
}

.project-btn {
  text-decoration: none;
  color: white;
  background-color: #70a288;
  padding: 15px;
  margin-bottom: 20px;
}

.project-btn:hover {
  background-color: black;
}

.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 250px;
  margin-top: 25px;
}

#user-name,
#user-email,
#user-text {
  -webkit-align-self: center;
          align-self: center;
  min-width: 50%;
  margin: 10px 0px;
}

.contact-btn {
  -webkit-align-self: center;
          align-self: center;
  text-decoration: none;
  color: white;
  background-color: #70a288;
  padding: 15px 25px;
  margin-bottom: 20px;
  border: none;
}

.contact-btn:hover {
  background-color: black;
}

.project-card {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.project-card,
.project-title-box,
.project-icon-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.project-logo {
  width: 40px;
  height: 40px;
}

.project-screen {
  max-width: 70%;
}

.project-tech-box {
  max-width: 80%;
  margin-bottom: 15px;
}

.project-icon-box {
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0px 5px;
}

.project-desc-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  max-width: 80%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.project-desc-title {
  margin-bottom: 0px;
}

.project-desc {
  max-width: 700px;
}

/* ---------- Link Box Styling ------------------ */

.project-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 0 15px 0;
  padding: 15px 0px;
  min-width: 100%;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
}

.project-link {
  color: white;
  display: block;
  font-size: 15px;
  height: 20px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px 4px 4px;
  position: relative;
  text-decoration: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

@media only screen and (max-width: 380px) {
  .project-link {
    font-size: 11px;
    padding: 0;
  }
}

@media only screen and (min-width: 550px) {
  .project-link {
    font-size: 20px;
  }
}

.project-link:hover {
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

/* Animated Bottom Line */
.project-link:before,
.project-link:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: -1px;
  background: black;
}

.project-link:before {
  left: 0;
  transition: 0.5s;
}

.project-link:after {
  background: black;
  right: 0;
}

.project-link:hover:before {
  background: black;
  width: 100%;
  transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

.project-link:hover:after {
  background: transparent;
  width: 100%;
}

/* ----------------- Mana Project ------------------- */

#mana-links {
  background-color: #292929;
}

#mana-link:hover {
  color: #9966cc;
}

#mana-desc-box {
  background-color: #292929;
}

/* ----------------- Shelf-Life Project ------------------- */

#shelf-links {
  background-color: #102b3f;
}

#shelf-link:hover {
  color: #6daad9;
}

#shelf-desc-box {
  background-color: #102b3f;
}

/* ----------------- Spaced Repetition Project ------------------- */

#spaced-title {
  color: #94ecbe;
}

#spaced-links {
  background-color: #1b2432;
}

#spaced-link:hover {
  color: #ba274a;
}

#spaced-desc-box {
  background-color: #1b2432;
  color: #94ecbe;
}

/* ----------------- Petful Project ------------------- */

#petful-title {
  margin: 20px 8px 12px 0px;
}

#petful-links {
  background-color: #252046;
}

#petful-link:hover {
  color: #685aaf;
}

#petful-desc-box {
  background-color: #252046;
}

/* ----------------- Icon styling ------------------- */

.logo {
  max-width: 12.5%;
  max-height: 12.5%;
}

/* ----------------- Tools styling ------------------- */

.tools {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  min-width: 100%;
  padding: 15px 0px;
  background-color: white;
}

.tool-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  min-width: 100%;
}

.tools-title {
    color: black;
}

footer {
  bottom: 0;
  min-width: 100%;
  height: 2.5rem;
}

.footer-box,
.contact-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.footer-box {
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #292929;
}

.contact-icons {
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 80%;
}

.github-box,
.linkedin-box,
.contact-box {
  min-width: 75px;
  min-height: 75px;
}

.email-icon, .github-icon {
  max-width: 75px;
  max-height: 75px;
}

