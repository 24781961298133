footer {
  bottom: 0;
  min-width: 100%;
  height: 2.5rem;
}

.footer-box,
.contact-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-box {
  flex-direction: column;
  background-color: #292929;
}

.contact-icons {
  flex-direction: row;
  width: 80%;
}

.github-box,
.linkedin-box,
.contact-box {
  min-width: 75px;
  min-height: 75px;
}

.email-icon, .github-icon {
  max-width: 75px;
  max-height: 75px;
}
