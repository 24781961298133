.navbar-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-title {
  font-size: 80px;
  color: #70a288;
  margin-top: 25px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 380px) {
  .name-title {
    font-size: 40px;
  }
}

.navbar {
  background-color: #292929;
  padding: 20px 0px;
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
}

.navbar li a {
  color: white;
  display: block;
  font-size: 1.2em;
  height: 20px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px 4px 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

@media only screen and (max-width: 380px) {
  .navbar li a {
    font-size: 11px;
    padding: 0;
  }
}

@media only screen and (min-width: 550px) {
  .navbar li a {
    font-size: 20px;
  }
}

.navbar li a:hover {
  color: #70a288;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

/* Animated Bottom Line */
.navbar li a:before,
.navbar li a:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: -1px;
  background: black;
}

.navbar li a:before {
  left: 0;
  transition: 0.5s;
}

.navbar li a:after {
  background: black;
  right: 0;
}

.navbar li a:hover:before {
  background: black;
  width: 100%;
  transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

.navbar li a:hover:after {
  background: transparent;
  width: 100%;
}
